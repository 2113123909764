import { useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Background from './components/Background';
import Cart from './pages/Cart';
import { AuthContext } from './context/AuthContext';
import { auth } from './firebase';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Admin from './pages/Admin';

const App = () => {
  const { currentUser } = useContext(AuthContext);
  const [user, loading, error] = useAuthState(auth);

  const ProtectedRoute = ({ children }: { children: any }) => {
    if (!loading) {
      return !currentUser ? <Navigate to='/login' /> : children;
    }
  };

  return (
    <BrowserRouter>
      <Background />
      <Routes>
        <Route path='/'>
          <Route
            index
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path='login'
            element={<Login />}
          />
          <Route
            path='register'
            element={<Register />}
          />
          <Route
            path='cart'
            element={<Cart />}
          />
          <Route
            path='admin'
            element={<Admin />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
