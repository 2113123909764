import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: 'pizzabieres.firebaseapp.com',
  projectId: 'pizzabieres',
  storageBucket: 'pizzabieres.appspot.com',
  messagingSenderId: '461431662873',
  appId: '1:461431662873:web:3d28022785e85022e1bd88',
  measurementId: 'G-1VVVWBFJR3',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export  const storage = getStorage(app);
