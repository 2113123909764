import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import '../styles.scss';

const Register = (): JSX.Element => {
  const [error, setError] = React.useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setError(false);

    event.preventDefault();
    const target = event.target as typeof event.target & {
      username: { value: string };
      email: { value: string };
      password: { value: string };
    };

    const username = target.username.value;
    const email = target.email.value;
    const password = target.password.value;

    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      try {
        await setDoc(doc(db, 'users', response.user.uid), {
          uid: response.user.uid,
          username,
          email,
          favourites: [],
          order: [],
        });

        navigate('/');
      } catch (error) {
        console.error(error);
        setError(true);
      }
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  return (
    <div className='formContainer'>
      <div className='formWrapper'>
        <span className='title'>Nouveau compte</span>
        <form onSubmit={handleSubmit}>
          <input
            required
            name='username'
            type='text'
            placeholder='prénom nom 🍕'
          />
          <input required name='email' type='text' placeholder='email 🍺' />
          <input
            required
            name='password'
            type='password'
            placeholder='mot de passe 🦄'
          />
          <button>Et le compte fût</button>
          {error && <span className='error'>Something went wrong</span>}
        </form>
        <p>
          Tu as déjà un compte ? <Link to='/login'>Connecte-toi</Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
