import React, { useContext } from 'react';
import { ProfileCardContext } from '../context/ProfileCardContext';
import { UserContext } from '../context/UserContext';
import { User } from '../models/user';

function ProfileCard() {
  const { user }: { user: User } = useContext(UserContext);
  const { setProfileCard }: { setProfileCard: Function } =
    useContext(ProfileCardContext);

  const closeProfileCard = () => {
    setProfileCard(false);
  };

  return (
    <button
      className='profileCard'
      onClick={closeProfileCard}
    >
      <div className='nameField'>
        <span className='name'>Nom : </span>
        <span className='nameValue'>{user.username}</span>
      </div>
    </button>
  );
}

export default ProfileCard;
