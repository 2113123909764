import { createContext, useEffect, useState } from 'react';

export const CartContext = createContext<any>({} as any);

export const CartContextProvider = ({ children }: any) => {
  const [cart, setCart] = useState<string[]>([]);

  useEffect(() => {
    const unsub = () => setCart(cart);

    return () => unsub();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CartContext.Provider value={{ cart: cart, setCart: setCart }}>
      {children}
    </CartContext.Provider>
  );
};
