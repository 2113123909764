import { useContext, useState } from 'react';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { FaShoppingCart } from 'react-icons/fa';
import { MdMenuBook } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../context/CartContext';
import { CornerMenuContext } from '../context/CornerMenuContext';
import { LogoutContext } from '../context/LogoutContext';
import { ProfileCardContext } from '../context/ProfileCardContext';
import { Page } from '../models/page';
import '../styles.scss';
import Logout from './Logout';
import ProfileCard from './ProfileCard';
import { AdminMenuContext } from '../context/AdminMenuContext';

const Menu = (): JSX.Element => {
  const navigate = useNavigate();
  const { cart } = useContext(CartContext);
  const { cornerMenu, setCornerMenu } = useContext(CornerMenuContext);
  const { adminMenu } = useContext(AdminMenuContext);
  const { logout, setLogout } = useContext(LogoutContext);
  const { profileCard, setProfileCard } = useContext(ProfileCardContext);

  const handleSignout = () => {
    setLogout(true);
  };

  const handleCartClick = () => {
    navigate('/cart');
  };

  const handlePizzaGridClick = () => {
    setCornerMenu(Page.pizzaGrid);
    navigate('/');
  };

  const showProfileCard = () => {
    setProfileCard(true);
  };

  const handleAdminButtonClick = () => {
    if (cornerMenu === Page.pizzaGrid) {
      navigate(adminMenu === Page.pizzaGrid ? '/admin' : '/');
    } else {
      navigate(adminMenu === Page.pizzaGrid ? '/admin' : '/cart');
    }
  };

  return (
    <>
      {logout && <Logout />}
      {profileCard && <ProfileCard />}
      {!profileCard && (
        <button
          className='profileMenu'
          onClick={showProfileCard}
        >
          <span>i</span>
        </button>
      )}
      <div className='cornerMenu'>
        {cornerMenu === Page.pizzaGrid ? (
          <button
            onClick={handleCartClick}
            disabled={adminMenu === Page.admin}
          >
            {cart.length > 0 ? (
              <FaShoppingCart className='shoppingCart' />
            ) : (
              <AiOutlineShoppingCart className='shoppingCart' />
            )}
            <span>{' Panier'}</span>
          </button>
        ) : (
          <button
            onClick={handlePizzaGridClick}
            disabled={adminMenu === Page.admin}
          >
            <MdMenuBook className='menuBook' />
            <span>{' Carte'}</span>
          </button>
        )}
        <button onClick={handleSignout}>Se déconnecter</button>
      </div>
      <button
        className='adminButton'
        onClick={handleAdminButtonClick}
      >
        <span>{adminMenu === Page.pizzaGrid ? 'Utilisateur' : 'Admin'}</span>
      </button>
    </>
  );
};

export default Menu;
