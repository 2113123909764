import { useContext, useEffect } from 'react';
import Menu from '../components/Menu';
import { AdminMenuContext } from '../context/AdminMenuContext';
import { Page } from '../models/page';

function Admin() {
  const { setAdminMenu } = useContext(AdminMenuContext);

  useEffect(() => {
    setAdminMenu(Page.admin);
  }, []);

  return (
    <>
      <Menu />
      <div>Admin</div>
    </>
  );
}

export default Admin;
