import { createContext, useEffect, useState } from 'react';
import { User } from '../models/user';

export const UserContext = createContext<any>({} as any);

export const UserContextProvider = ({ children }: any) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsub = () => setUser(user);

    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
