import Menu from '../components/Menu';
import PizzaGrid from '../components/PizzaGrid';

const Home = (): JSX.Element => {
  return (
    <>
      <Menu />
      <PizzaGrid />
    </>
  );
};

export default Home;
