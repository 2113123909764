import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      main: '#cd561e',
      light: '#f6ba99',
      dark: '#1b1b1b',
    },
    secondary: {
      main: '#e65100',
      light: '#ff833a',
      dark: '#ac1900',
    },
    text: {
      primary: '#ffffff',
      secondary: '#000000',
      disabled: '#757575',
    },
  },
  typography: {
    fontFamily: 'Lato',
    h5: {
      fontWeight: 700,
    },
  },
});
