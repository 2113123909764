import { createContext, useEffect, useState } from 'react';
import { Page } from '../models/page';

export const CornerMenuContext = createContext<any>({} as any);

export const CornerMenuContextProvider = ({ children }: any) => {
  const [cornerMenu, setCornerMenu] = useState<Page | null>(null);

  useEffect(() => {
    const unsub = () => setCornerMenu(cornerMenu);

    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CornerMenuContext.Provider
      value={{ cornerMenu: cornerMenu, setCornerMenu: setCornerMenu }}
    >
      {children}
    </CornerMenuContext.Provider>
  );
};
