import {
  browserLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';

const Login = (): JSX.Element => {
  const [error, setError] = React.useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setError(false);
    event.preventDefault();

    const target = event.target as typeof event.target & {
      email: { value: string };
      password: { value: string };
    };

    const email = target.email.value;
    const password = target.password.value;

    setPersistence(auth, browserLocalPersistence).then(async () => {
      try {
        await signInWithEmailAndPassword(auth, email, password);
        navigate('/');
      } catch (error) {
        console.error(error);
        setError(true);
      }
    });
  };

  return (
    <div className='formContainer'>
      <div className='formWrapper'>
        <span className='title'>Connexion</span>
        <form onSubmit={handleSubmit}>
          <input name='email' type='email' placeholder='email 🍕' />
          <input
            name='password'
            type='password'
            placeholder='mot de passe 🦄'
          />
          <button>Se connecter</button>
          {error && <span>Something went wrong</span>}
        </form>
        <p>
          Tu n'as pas de compte ? Clique <Link to='/register'>ici</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
