import { createContext, useEffect, useState } from 'react';
import { Page } from '../models/page';

export const LogoutContext = createContext<any>({} as any);

export const LogoutContextProvider = ({ children }: any) => {
  const [logout, setLogout] = useState<boolean>(false);

  useEffect(() => {
    const unsub = () => setLogout(logout);

    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LogoutContext.Provider value={{ logout: logout, setLogout: setLogout }}>
      {children}
    </LogoutContext.Provider>
  );
};
