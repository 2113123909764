/* eslint-disable react-hooks/exhaustive-deps */
import {
  DocumentData,
  DocumentReference,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { CartContext } from '../context/CartContext';
import { PizzaContext } from '../context/PizzaContext';
import { UserContext } from '../context/UserContext';
import { Pizza } from '../models/pizza';
import { User } from '../models/user';
import { useNavigate } from 'react-router-dom';

const Background = (): JSX.Element => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const {
    allPizzas,
    setAllPizzas,
  }: { allPizzas: Pizza[]; setAllPizzas: Function } = useContext(PizzaContext);
  const { cart, setCart }: { cart: string[]; setCart: Function } =
    useContext(CartContext);
  const { user, setUser } = useContext(UserContext);
  const [pizzas, setPizzas] = useState<Pizza[]>([]);
  const [userDoc, setUserDoc] =
    useState<DocumentReference<DocumentData> | null>(null);
  let password = '';
  const db = getFirestore();
  const storage = getStorage();
  const pizzaCollection = collection(db, 'pizzas');

  const getPizzaFromDb = async (): Promise<void> => {
    setPizzas(
      Array.from(
        new Set(
          (await getDocs(pizzaCollection)).docs.map(
            (doc) => doc.data() as Pizza
          )
        )
      )
    );
  };

  const getPizzaImages = async (): Promise<void> => {
    for (let pizza of pizzas) {
      let url = await getDownloadURL(ref(storage, pizza.photo));
      // console.log(pizza, url);
      await setAllPizzas((oldPizzas: Pizza[]) => [
        ...oldPizzas.filter((p) => p.name !== pizza.name),
        { ...pizza, photoUrl: url },
      ]);
    }
  };

  const getUserDoc = () => {
    setUserDoc(doc(db, 'users', currentUser!.uid));
  };

  const getUserData = async (): Promise<void> => {
    setUser((await getDoc(userDoc!)).data() as User);
  };

  useEffect(() => {
    if (currentUser !== null) {
      getPizzaFromDb();
    }
  }, [currentUser]);

  useEffect(() => {
    getPizzaImages();
  }, [pizzas]);

  useEffect(() => {
    if (currentUser !== null) {
      getUserDoc();
    }
  }, [currentUser]);

  useEffect(() => {
    if (userDoc !== null) {
      getUserData();
    }
  }, [userDoc]);

  useEffect(() => {
    if (user) {
      setCart((user as User).order);
    }
  }, [user]);

  return (
    <div className='background'>
      <div className='title'>Pizza - Bières</div>
    </div>
  );
};

export default Background;
