import { signOut } from 'firebase/auth';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogoutContext } from '../context/LogoutContext';
import { auth } from '../firebase';

function Logout() {
  const navigate = useNavigate();
  const { setLogout } = useContext(LogoutContext);

  const handleSignout = () => {
    signOut(auth).then(() => navigate('/login'));
    setLogout(false);
  };

  const handleCancelSignout = () => {
    setLogout(false);
  };

  return (
    <div>
      <div className='logoutContainer'>
        <div className='logoutCard'>
          <span className='title'>Déconnexion</span>
          <p>Êtes-vous sûr de vouloir vous déconnecter ?</p>
          <div className='logoutButtons'>
            <button
              className='logoutButton'
              onClick={handleSignout}
            >
              Oui
            </button>
            <button
              className='logoutButton'
              onClick={handleCancelSignout}
            >
              Non
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Logout;
