import { createContext, useEffect, useState } from 'react';
import { Page } from '../models/page';

export const AdminMenuContext = createContext<any>({} as any);

export const AdminMenuContextProvider = ({ children }: any) => {
  const [adminMenu, setAdminMenu] = useState<Page | null>(null);

  useEffect(() => {
    const unsub = () => setAdminMenu(adminMenu);

    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminMenuContext.Provider
      value={{ adminMenu: adminMenu, setAdminMenu: setAdminMenu }}
    >
      {children}
    </AdminMenuContext.Provider>
  );
};
