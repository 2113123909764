import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';
import { CartContext } from '../context/CartContext';
import { CornerMenuContext } from '../context/CornerMenuContext';
import { PizzaContext } from '../context/PizzaContext';
import { Page } from '../models/page';
import { Pizza } from '../models/pizza';
import { deepCopy } from '../utilities/functions';
import { AdminMenuContext } from '../context/AdminMenuContext';

const Cart = (): JSX.Element => {
  const navigate = useNavigate();
  const { cart, setCart } = useContext(CartContext);
  const { allPizzas } = useContext(PizzaContext);
  const { setCornerMenu } = useContext(CornerMenuContext);
  const { setAdminMenu } = useContext(AdminMenuContext);
  const [order, setOrder] = useState<Pizza[]>([]);

  useEffect(() => {
    setCornerMenu(Page.cart);
    setAdminMenu(Page.pizzaGrid);
  }, [setCornerMenu]);

  useEffect(() => {
    if (cart && allPizzas) {
      setOrder([
        ...allPizzas
          .filter((pizza: Pizza) => cart.includes(pizza.name))
          .map((pizza: Pizza) => deepCopy(pizza)),
      ]);
    }
  }, [cart, allPizzas]);

  return (
    <>
      <Menu />
      <div className='cartContainer'>
        <div className='cartCard'>
          <span className='title'>Panier</span>
          <div className='orderContainer'>
            {cart && allPizzas && (
              <>
                {order.map((pizza: Pizza) => (
                  <div
                    key={pizza.name}
                    className='orderRow'
                  >
                    <img
                      src={pizza.photoUrl}
                      alt=''
                    />
                    <span>{pizza.name}</span>
                    <div className='comment'>
                      <span>Commentaires</span>
                      <input/>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
