import React from 'react';
import { FaPepperHot } from 'react-icons/fa';
import { Pizza } from '../models/pizza';

const Ingredients = (props: {
  pizza: Pizza;
  closer: Function;
  [key: string]: any;
}): JSX.Element => {
  return (
    <div
      className='ingredientsContainer'
      onClick={(event) => props.closer(false)}
    >
      <div
        className='ingredientsCard'
        onClick={(event) => event.stopPropagation()}
      >
        <span className='title'>
          {props.pizza.name}{' '}
          {props.pizza.canBeSpicy && <FaPepperHot className='spicy' />}
        </span>
        <ul>
          {props.pizza.ingredients.map((ingredient: string) => (
            <li key={ingredient}>{ingredient}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Ingredients;
