/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { useContext, useEffect, useState } from 'react';
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillEye,
  AiFillHeart,
  AiOutlineHeart,
} from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../context/CartContext';
import { CornerMenuContext } from '../context/CornerMenuContext';
import { PizzaContext } from '../context/PizzaContext';
import { UserContext } from '../context/UserContext';
import { auth } from '../firebase';
import { Page } from '../models/page';
import { Pizza } from '../models/pizza';
import { User } from '../models/user';
import '../styles.scss';
import { deepCopy } from '../utilities/functions';
import Ingredients from './Ingredients';
import { theme } from './style';
import { AdminMenuContext } from '../context/AdminMenuContext';

const PizzaGrid = (): JSX.Element => {
  const navigate = useNavigate();
  const { cart, setCart }: { cart: string[]; setCart: Function } =
    useContext(CartContext);
  const {
    allPizzas,
    setAllPizzas,
  }: { allPizzas: Pizza[]; setAllPizzas: Function } = useContext(PizzaContext);
  const {
    cornerMenu,
    setCornerMenu,
  }: { cornerMenu: Page; setCornerMenu: Function } =
    useContext(CornerMenuContext);
  const {
    setAdminMenu,
  }: { adminMenu: Page; setAdminMenu: Function } = useContext(AdminMenuContext);
  const [pizzaList, setPizzaList]: [Pizza[], Function] = useState([]);
  const [loading, setLoading]: [boolean, Function] = useState(true);
  const { user }: { user: User; setUser: Function } = useContext(UserContext);
  const [updatingFavourites, setUpdatingFavourites]: [boolean, Function] =
    useState(false);
  const [showOverlay, setShowOverlay]: [boolean, Function] = useState(false);
  const [shownPizza, setShownPizza]: [Pizza | null, Function] =
    useState<Pizza | null>(null);
  const [order, setOrder]: [string[], Function] = useState([]);
  const [favouritesButtonClicked, setFavouritesButtonClicked]: [
    boolean,
    Function
  ] = useState(false);

  const db = getFirestore();

  const getPizzaList = (): void => {
    setPizzaList([...allPizzas.map((pizza) => deepCopy(pizza))]);
  };

  const applyFavouritesAndOrder = (): void => {
    if (user !== null) {
      for (let pizza of pizzaList) {
        pizza['isFavourite'] = (user as User).favourites.includes(pizza.name);
      }
      setOrder(cart);
    }
  };

  /**
   * set corner menu context
   */

  useEffect(() => {
    setCornerMenu(cornerMenu ? cornerMenu : Page.pizzaGrid);
    setAdminMenu(Page.pizzaGrid);
  }, []);

  /**
   * construct pizza list from db pizzas
   */
  useEffect(() => {
    // console.log('get pizzalist');
    getPizzaList();
  }, [allPizzas]);

  /**
   * fills isFavourite fields on pizza objects and fills order array
   */
  useEffect(() => {
    if (pizzaList && user && !updatingFavourites) {
      applyFavouritesAndOrder();
    }
    setUpdatingFavourites(false);
  }, [pizzaList]);

  useEffect(() => {
    setLoading(pizzaList.length !== 11);
  }, [pizzaList]);

  /**
   * replace cart with favourites and navigate to cart page
   */
  useEffect(() => {
    if (favouritesButtonClicked) {
      handleAddToCart();
      setFavouritesButtonClicked(false);
      navigate('/cart');
    }
  }, [order]);

  const handleFavouriteChange = async (pizza: Pizza): Promise<void> => {
    // If user is logged in
    if (user != null) {
      // Toggle favourite status
      pizza.isFavourite = !pizza.isFavourite;

      // Update pizza list
      setPizzaList((oldPizzaList: Pizza[]) =>
        oldPizzaList.map((p) => {
          setUpdatingFavourites(true);
          return p.name !== pizza.name ? p : pizza;
        })
      );

      // Update user's favourites
      if (!pizza.isFavourite) {
        (user as User).favourites = (user as User).favourites.filter(
          (favourite) => favourite !== pizza.name
        );
      } else {
        (user as User).favourites.push(pizza.name);
        (user as User).favourites = Array.from(
          new Set((user as User).favourites)
        );
      }

      // Update Firebase
      await setDoc(doc(db, 'users', (user as User).uid), user as User);
    }
  };

  const handlePizzaClick = (pizza: Pizza) => {
    // If the user is logged in, add the pizza to the order.
    if (user !== null) {
      setOrder((oldOrder: string[]) => {
        // If the pizza is already in the order, remove it.
        if (oldOrder.includes(pizza.name)) {
          return oldOrder.filter((p: string) => p !== pizza.name);
        } else {
          // Otherwise, add it.
          return [...oldOrder, pizza.name];
        }
      });
    }
  };

  const handleAddToCart = async () => {
    if (user !== null) {
      setCart(order);
      (user as User).order = order;

      await setDoc(doc(db, 'users', (user as User).uid), user as User);
    }
  };

  const handleFavouritesButtonClick = () => {
    setFavouritesButtonClicked(true);
    setOrder([
      ...pizzaList
        .filter((p: Pizza) => p.isFavourite)
        .map((p: Pizza) => p.name),
    ]);
  };

  return (
    <>
      <div className='pizzaGridContainer'>
        {!loading && (
          <>
            <div className='pizzaGrid'>
              {pizzaList.map((pizza: Pizza) => (
                <div
                  key={pizza.name}
                  className={
                    order.includes(pizza.name) || cart.includes(pizza.name)
                      ? 'pizzaCard selected'
                      : 'pizzaCard'
                  }
                >
                  {pizza.photoUrl ? (
                    <img
                      src={pizza.photoUrl}
                      alt={pizza.name.toLowerCase().replaceAll(' ', '_')}
                      draggable={false}
                      onClick={() => handlePizzaClick(pizza)}
                    />
                  ) : (
                    <ThemeProvider theme={theme}>
                      <CircularProgress color='primary' />
                    </ThemeProvider>
                  )}
                  <div className='title'>{pizza.name}</div>
                  {pizza.isFavourite ? (
                    <AiFillHeart
                      className='button favouritesButton'
                      onClick={() => handleFavouriteChange(pizza)}
                    />
                  ) : (
                    <AiOutlineHeart
                      className='button favouritesButton'
                      onClick={() => handleFavouriteChange(pizza)}
                    />
                  )}
                  <AiFillEye
                    className='button detailsButton'
                    onClick={() => {
                      setShowOverlay(true);
                      setShownPizza(pizza);
                    }}
                  />
                  <AiFillCheckCircle
                    className={
                      order.includes(pizza.name)
                        ? 'button selectedIcon check'
                        : 'button hiddenIcon'
                    }
                  />
                  <AiFillCloseCircle
                    className={
                      !order.includes(pizza.name) && cart.includes(pizza.name)
                        ? 'button selectedIcon remove'
                        : 'button hiddenIcon'
                    }
                  />
                </div>
              ))}
            </div>
            <button
              onClick={handleAddToCart}
              className='orderButton'
              disabled={order.length === 0 && cart.length === 0}
            >
              Confirmer les pizzas
            </button>
            <button
              onClick={handleFavouritesButtonClick}
              className='orderFavouritesButton'
              disabled={user.favourites.length === 0}
            >
              Commander{' '}
              {user.favourites.length > 1 ? 'les favorites' : 'la favorite'}
            </button>
          </>
        )}
      </div>
      {showOverlay && (
        <Ingredients
          pizza={shownPizza!}
          closer={setShowOverlay}
        />
      )}
    </>
  );
};

export default PizzaGrid;
