import { createContext, useEffect, useState } from 'react';

export const ProfileCardContext = createContext<any>({} as any);

export const ProfileCardContextProvider = ({ children }: any) => {
  const [profileCard, setProfileCard] = useState<boolean>(false);

  useEffect(() => {
    const unsub = () => setProfileCard(profileCard);

    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProfileCardContext.Provider
      value={{ profileCard: profileCard, setProfileCard: setProfileCard }}
    >
      {children}
    </ProfileCardContext.Provider>
  );
};
