import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { CartContextProvider } from './context/CartContext';
import { CornerMenuContextProvider } from './context/CornerMenuContext';
import { LogoutContextProvider } from './context/LogoutContext';
import { PizzaContextProvider } from './context/PizzaContext';
import { ProfileCardContextProvider } from './context/ProfileCardContext';
import { UserContextProvider } from './context/UserContext';
import './index.scss';
import { AdminMenuContextProvider } from './context/AdminMenuContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AuthContextProvider>
    <LogoutContextProvider>
      <PizzaContextProvider>
        <UserContextProvider>
          <CartContextProvider>
            <CornerMenuContextProvider>
              <AdminMenuContextProvider>
                <ProfileCardContextProvider>
                  <App />
                </ProfileCardContextProvider>
              </AdminMenuContextProvider>
            </CornerMenuContextProvider>
          </CartContextProvider>
        </UserContextProvider>
      </PizzaContextProvider>
    </LogoutContextProvider>
  </AuthContextProvider>
);
