import { createContext, useEffect, useState } from 'react';
import { Pizza } from '../models/pizza';

export const PizzaContext = createContext<any>({} as any);

export const PizzaContextProvider = ({ children }: any) => {
  const [allPizzas, setAllPizzas] = useState<Pizza[]>([]);

  useEffect(() => {
    const unsub = () => setAllPizzas(allPizzas);

    return () => unsub();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PizzaContext.Provider
      value={{ allPizzas: allPizzas, setAllPizzas: setAllPizzas }}
    >
      {children}
    </PizzaContext.Provider>
  );
};
